<template>
  <v-row class="m-0 p-0" v-bg:b>
    <v-col col="12">
      <v-card outlined class="mt-1 mb-0 pb-0">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i class="btn-icon-left" v-i:duo#chalkboard-teacher#18 v-c:P></i>
            Avaliação & Desempenho
          </p>
          <div class="left mt-0 mb-0 pt-0 ms-4" v-if="equipe != null">
            <avataronline v-for="(user,key,index) in equipe.pessoas" :key="index" :userID="user.id" :size="34"/>
          </div>
        </v-card-title>
        <!-- MENU -->
        <topmenu @modelToggle="modelToggle" :key="4"/>
      </v-card>

      <div class="mt-2" v-if="modelToggleSel =='AREA'">
        <areapage />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='minhasucs'">
        <minhasucs />
      </div>
      <div class="mt-n2" v-if="modelToggleSel =='minhasencomendas'">
        <minhasencomendas />
      </div>
      <div class="mt-3" v-if="modelToggleSel =='atividades'">
        <atividades />
      </div>
      <div class="mt-3" v-if="modelToggleSel =='indicacoes'">
        <indicacoes />
      </div>
      <div class="mt-3" v-if="modelToggleSel =='encomendas'">
        <encomendas />
      </div>
      <div class="mt-3" v-if="modelToggleSel =='recursos'">
        <recursos />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='visualizacao'">
        <encomendasvisualizacao />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='entregas'">
        <encomendasentrega />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='recursosA2'">
        <recursosA2router />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='distribuicao'">
        <distribuicao />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='importacao'">
        <importacao />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='cards'">
        <v-row>
          <v-col cols="12" md="6" lg="5">
            <carducsquestoes />
          </v-col>
        </v-row>
      </div>

    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import { notification } from '@/components/notification/notification.js'
import avataronline from "@/components/avatar-online.vue";

export default {
  name: 'avaliacaohome',
  components: { avataronline,
    'topmenu': () => import('./topmenu/top-menu.vue'),
    'areapage': () => import('./area-page/area-page.vue'),
    'minhasucs': () => import('./minhasucs.vue'),
    'minhasencomendas': () => import('@/views/profile/encomendas/encomendas.vue'),
    'atividades': () => import('./atividades.vue'),
    'indicacoes': () => import('./acompanhamento/acompanhamento.vue'),
    'encomendas': () => import('./encomendas/encomendas.vue'),
    'encomendasvisualizacao': () => import('./encomendas/acompanhamento/encomendas-visualizacao.vue'),
    'encomendasentrega': () => import('./encomendas/acompanhamento/encomendas-entrega.vue'),
    'distribuicao': () => import('./encomendas/distribuicao/ucs.vue'),
    'carducsquestoes': () => import('./encomendas/cards/card-metas-questoes.vue'),
    'carducmetas': () => import('./encomendas/cards/card-ucs-metas.vue'),
    'carducsprofs': () => import('./encomendas/cards/card-ucs-profs.vue'),
    'importacao': () => import('./encomendas/importacao/importacao.vue'),
    'recursosA2router': () => import('./recursosA2/router.vue'),
    //'recursos': () => import('./recursos2021-1/admRecursos.vue'),    
  },
  computed: {
    adminAvaliacao() {
      var equipe = this.$store.state.admin.equipesByName["Administradores Avaliação & Desempenho"];
      return (equipe.pessoas[this.userLog.id] != undefined);
    }
  },
  data () {
    return {
      tab: null,
      equipe: {},
      modelToggleSel: "minhasencomendas",
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    rdb.ref('/usuarios/equipes/-MgLXWYKX4opnIpDWaZF').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      //console.log("self.equipe",self.equipe);
    });

  },
  methods: {

    modelToggle(model) {
      console.log("modelToggle",model);
      this.modelToggleSel = model;
    }

  }
}
</script>
